import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import PropTypes from 'prop-types'
import styles from './style.module.scss'

import noImage from '@assets/images/placeholder/article-placeholder.jpg'
import useOnMouseAndScroll from '@shared/hooks/useOnMouseAndScroll'

function MyImage({ src, placeholderImage, errorImage, loadOnScroll, quality = 70, height, width, className, ...rest }) {
  const [url, setUrl] = useState(src)
  const { isLoaded } = useOnMouseAndScroll()

  useEffect(() => {
    setUrl(src)
  }, [src])

  function getUrl() {
    if (loadOnScroll) {
      return isLoaded ? url : placeholderImage
    } else {
      return url
    }
  }

  return (
    <Image
      src={getUrl()}
      unoptimized={true}
      objectFit="cover"
      quality={quality}
      className={`${styles.imageContainer} ${className}`}
      onError={e => setUrl(errorImage || noImage)}
      height={height}
      width={width}
      {...rest}
    />
  )
}
MyImage.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errorImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholderImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loadOnScroll: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  quality: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
}
export default React.memo(MyImage)
